import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/bidingLosses/page',
    method: 'post',
    data: query
  })
}

/**
 * 根据id查询中标损益信息
 */
export function getObj (id) {
  return request({
    url: '/tms/bidingLosses/queryPieceData/' + id,
    method: 'get'
  })
}
/**
 *  新增保存
 */
export function addObj (obj) {
  return request({
    url: '/tms/bidingLosses/insertTmsIntroduce',
    method: 'post',
    data: obj
  })
}
/**
 * 修改保存
 */
export function putObj (obj) {
  return request({
    url: '/tms/bidingLosses/updateTmsIntroduce',
    method: 'post',
    data: obj
  })
}

/**
 * 批量删除
 */
export function delObjs (ids) {
  return request({
    url: '/tms/bidingLosses/batchDeleteData',
    method: 'delete',
    params: ids
  })
}
/**
 * 根据招标编号查询中标损益
 */
export function getBidingLoss (obj) {
  return request({
    url: '/tms/bidingLosses/selectByTenderNo',
    method: 'get',
    params: obj
  })
}
/**
 * 判断是否重复
 */
export function getDetails (obj) {
  return request({
    url: '/basic/dtcustomer/details/' + obj,
    method: 'get'
  })
}
/**
 * 通过ids进行区总审核
 */
export function cusRegion (ids) {
  return request({
    url: '/tms/bidingLosses/auditRegion',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行区总反审核
 */
export function cusUnRegion (ids) {
  return request({
    url: '/tms/bidingLosses/unAuditRegion',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行市场审核
 */
export function cusMarket (ids) {
  return request({
    url: '/tms/bidingLosses/auditMarket',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行市场反审核
 */
export function cusUnMarket (ids) {
  return request({
    url: '/tms/bidingLosses/unAuditMarket',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行财务审核
 */
export function cusFinance (ids) {
  return request({
    url: '/tms/bidingLosses/auditFinance',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行财务反审核
 */
export function cusUnFinance (ids) {
  return request({
    url: '/tms/bidingLosses/unAuditFinance',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行运营审核
 */
export function cusOperate (ids) {
  return request({
    url: '/tms/bidingLosses/auditOperate',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行运营反审核
 */
export function cusUnOperate (ids) {
  return request({
    url: '/tms/bidingLosses/unAuditOperate',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行人力审核
 */
export function cusPersonnel (ids) {
  return request({
    url: '/tms/bidingLosses/auditPersonnel',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行人力反审核
 */
export function cusUnPersonnel (ids) {
  return request({
    url: '/tms/bidingLosses/unAuditPersonnel',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行采购审核
 */
export function cusPurchase (ids) {
  return request({
    url: '/tms/bidingLosses/auditPurchase',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行采购反审核
 */
export function cusUnPurchase (ids) {
  return request({
    url: '/tms/bidingLosses/unAuditPurchase',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行副总审核
 */
export function cusPresident (ids) {
  return request({
    url: '/tms/bidingLosses/auditPresident',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行副总反审核
 */
export function cusUnPresident (ids) {
  return request({
    url: '/tms/bidingLosses/unAuditPresident',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行总经理审核
 */
export function cusManager (ids) {
  return request({
    url: '/tms/bidingLosses/auditManager',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行总经理反审核
 */
export function cusUnManager (ids) {
  return request({
    url: '/tms/bidingLosses/unAuditManager',
    method: 'post',
    params: ids
  })
}
/**
 * 上传阿里
 */
export function uploadAliBidLoss (obj) {
  return request({
    url: '/tms/ossobject/bidingLossesUpload',
    method: 'post',
    data: obj
  })
}
/**
 * 打印
 */
export function printIntroduce (obj) {
  return request({
    url: '/tms/tmsIntroduce/printingIntroduceReview',
    method: 'post',
    responseType: 'blob',
    params: obj
  })
}
/**
 * 进程状态
 */
export function updateProcessState (obj) {
  return request({
    url: '/tms/tmsIntroduce/updateProcessState',
    method: 'post',
    params: obj
  })
}
/**
 * 通过ids进行 区总拒审
 */
export function auditRegionFailed (obj) {
  return request({
    url: '/tms/bidingLosses/auditRegionFailed',
    method: 'post',
    params: obj
  })
}
/**
 * 通过ids进行 市场拒审
 */
export function auditMarketFailed (obj) {
  return request({
    url: '/tms/bidingLosses/auditMarketFailed',
    method: 'post',
    params: obj
  })
}
/**
 * 通过ids进行 采购拒审
 */
export function auditPurchaseFailed (obj) {
  return request({
    url: '/tms/bidingLosses/auditPurchaseFailed',
    method: 'post',
    params: obj
  })
}
/**
 * 通过ids进行 运营拒审
 */
export function auditOperateFailed (obj) {
  return request({
    url: '/tms/bidingLosses/auditOperateFailed',
    method: 'post',
    params: obj
  })
}
/**
 * 通过ids进行 人力拒审
 */
export function auditPersonnelFailed (obj) {
  return request({
    url: '/tms/bidingLosses/auditPersonnelFailed',
    method: 'post',
    params: obj
  })
}
/**
 * 通过ids进行 财务拒审
 */
export function auditFinanceFailed (obj) {
  return request({
    url: '/tms/bidingLosses/auditFinanceFailed',
    method: 'post',
    params: obj
  })
}
/**
 * 通过ids进行 副总拒审
 */
export function auditPresidentFailed (obj) {
  return request({
    url: '/tms/bidingLosses/auditPresidentFailed',
    method: 'post',
    params: obj
  })
}
/**
 * 通过ids进行 总经理拒审
 */
export function auditManagerFailed (obj) {
  return request({
    url: '/tms/bidingLosses/auditManagerFailed',
    method: 'post',
    params: obj
  })
}
/**
 * 查询审批流程信息
 */
export function getApproveData () {
  return request({
    url: '/tms/tmsWorkflow/queryWorkflowData',
    method: 'get'
  })
}
/**
 * 新增或修改 保存审批流程信息
 */
export function saveApproveData (obj) {
  return request({
    url: '/tms/tmsWorkflow/insertOrUpdateWork',
    method: 'post',
    data: obj
  })
}
/**
 * 判断立项报告是否全部都审核
 */
export function judgeWhetherAudit (obj) {
  return request({
    url: '/tms/tmsIntroduce/selectIntroduceWhetherAudit',
    method: 'post',
    params: obj
  })
}
/**
 * 询价单 取立项报告的 项目名称
 * @param obj 输入搜索信息
 * @returns {AxiosPromise}
 */
export function getIntroduceProject (projectName) {
  return request({
    url: '/tms/tmsIntroduce/fuzzySelectProjectName',
    method: 'post',
    params: { projectName: projectName }
  })
}
/**
 * 询价单 根据立项报告 的项目ID查询行业负责人信息
 * @param obj 输入搜索信息
 * @returns {AxiosPromise}
 */
export function getIntroducer (id) {
  return request({
    url: '/tms/tmsIntroduce/accordingProjectIdQuery',
    method: 'post',
    params: { id: id }
  })
}
/**
 * 中标损益 根据立项报告 的项目名称查询招标编号options
 * @param obj 输入搜索信息
 * @returns {AxiosPromise}
 */
export function getTenderNo (projectName) {
  return request({
    url: '/tms/tmsIntroduce/projectNameQueryBidding',
    method: 'get',
    params: { projectName: projectName }
  })
}
/**
 * 立项报告 同步项目 判断项目是新项目还是老项目
 * @param obj 输入搜索信息
 * @returns {AxiosPromise}
 * 返回信息
 * code = 0 msg = "0"  是新客户
 * code = 0 msg = "1"  是老客户
 * code = 1 返回错误信息
 */
export function judgeProjectStatus (customersId) {
  return request({
    url: '/tms/tmsCustomers/determineCurrentProjectStatus',
    method: 'post',
    params: { customersId: customersId }
  })
}
/**
 * 立项报告 同步项目 老项目同步更新项目详情的招标信息
 * @param obj 输入搜索信息
 * @returns {AxiosPromise}
 */
export function saveCustomers (customersId) {
  return request({
    url: '/tms/tmsCustomers/saveCustomersDisplayStatus',
    method: 'post',
    params: { customersId: customersId }
  })
}
